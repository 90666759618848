<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider
                            name="name"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-input-group class="mb-3" >
                                <b-form-input
                                    v-model="form.name"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            ></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col>
                    <ValidationProvider name="evaluation_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('evaluation')">
                            <evaluation-selectbox v-model="form.evaluation_id"></evaluation-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="12">
                    <b-form-group :label="$t('goal')">
                        <ValidationProvider
                            name="goal"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-input-group class="mb-3" >
                                <b-form-input
                                    v-model="form.goal"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            ></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('learning_outcomes')">
                        <ValidationProvider
                            name="learning_outcomes"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-input-group class="mb-3" >
                                <b-form-input
                                    v-model="form.learning_outcomes"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            ></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                v-model="form.status"
                                :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
        </ValidationObserver>
        <b-col cols="12" class="pl-0">
            <b-button
                @click="updateForm"
                type="button"
                variant="primary"
                class="btn-lg mr-2"
            >
              {{ $t("save") | toUpperCase }}
            </b-button>
        </b-col>
    </div>
</template>

<script>
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import LevelService from "@/services/LevelService";
    //Components
    import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
    import EvaluationSelectbox from "@/components/interactive-fields/EvaluationSelectbox.vue";


    export default {
        components: {
            ValidationProvider, ValidationObserver, StatusSelectbox, EvaluationSelectbox,
        },
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                formLoading: false,
                form: {
                    name: null,
                    evaluation_id: null,
                    goal: null,
                    learning_outcomes: null,
                    status: null,
                }
            }
        },
        watch: {
            formId: function (val) {
                this.get(val)
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                LevelService.get(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                })
                                .catch((error) => {
                                    if (error.data.message) {
                                        this.$toast.error(this.$t("api." + error.data.message));
                                    }
                                }).finally(()=>{
                    this.formLoading = false;
                });
            },
            async updateForm() {
                if(this.checkPermission('level_update')) {
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid && this.formId) {
                        this.formLoading=true;
                        LevelService.update(this.formId, this.form)
                                        .then((response) => {
                                            this.formLoading = false;
                                            this.$toast.success(this.$t("api." + response.data.message));
                                            this.$emit("updateFormSuccess")
                                        })
                                        .catch((error) => {
                                            this.showErrors(error, this.$refs.formModalValidate)
                                        }).finally(() => {
                            this.formLoading = false;
                        })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            }
        }
    }
</script>
