<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group :label="$t('name')">
                    <b-input-group >
                        <div class="label-as-input">{{ isNotNullValue(form.name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('goal')">
                    <b-input-group >
                        <div class="label-as-input">{{ isNotNullValue(form.goal) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('learning_outcomes')">
                    <b-input-group >
                        <div class="label-as-input">{{ isNotNullValue(form.learning_outcomes) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('status')">
                    <b-input-group >
                        <div class="label-as-input">{{ isNotNullValue(getStatusText(form.status)) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //Service
    import LevelService from "@/services/LevelService";

    export default {
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                form: {
                  name: null,
                  evaluation_id: null,
                  goal: null,
                  learning_outcomes: null,
                  status: null,
                }
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                LevelService.get(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                })
                                .catch((error) => {
                                    this.showErrors(error)
                                })
            },

        }
    }
</script>
